import { useContext } from 'react';
import ModalContext from '../MakeAppointmentModal/ModalProvider';
import './DoctorsPreview.css';

const DoctorsPreview = ({ doctor }) => {
    const { modalOn, modalData } = useContext(ModalContext);

    const onClick = () => {
        modalData("Консультація - " + doctor.name, "ЗАПИСАТИСЯ НА КОНСУЛЬТАЦІЮ");
        modalOn();
    };

    return (
        <div className="doctor-container-preview">
            <div className="doctor-shadow"></div>
            <button onClick={onClick} className="doctor-hidden-button">
                <div className="doctor-container-data">
                    <img src={doctor.image} className="doctor-image-preview" />
                    <h2>{doctor.name}</h2>
                    <div className="doctor-field">{doctor.field}</div>
                    <h4 className="doctor-preview-h">Спеціалізація</h4>
                    <div>{doctor.specialization}</div>
                    <h4 className="doctor-preview-h">Досвід медичної практики</h4>
                    <div>{doctor.experience}</div>
                </div>
            </button>
        </div>
    );
};

export default DoctorsPreview;