import { useContext } from 'react';
import './MenuHeader.css';
import RouteUrl from '../RouteEnum';
import { Link } from "react-router-dom";
import { ContactContext } from '../App';


const MenuHeaderDesktop = ({ nested, transparent }) => {
    const context = useContext(ContactContext);
    const socialmedia = context.socialmedia;

    const prefix = "../".repeat(nested);

    return (
        <div className={"frame header-desktop-container " + (transparent ? "" : " with-bg")} style={{ paddingTop: "0" }}>
            <div className="header-desktop-menu">
                {nested === 0 ? (<div className="desktop-logo header-logo">&nbsp;</div>) :
                    (<Link to={RouteUrl.index} style={{ color: "inherit" }} relative="path"><div className="desktop-logo header-logo">&nbsp;</div></Link>)}
                <div className="header-link"><Link to={prefix + RouteUrl.medicalFields} style={{ color: "inherit" }} relative="path">НАПРЯМКИ</Link></div>
                <div className="header-link"><Link to={prefix + RouteUrl.doctors} style={{ color: "inherit" }} relative="path">ЛІКАРІ</Link></div>
                <div className="header-link"><Link to={prefix + RouteUrl.procedures} style={{ color: "inherit" }} relative="path">МЕТОДИКИ ТА ПРОЦЕДУРИ</Link></div>
                <div className="header-link"><Link to={prefix + RouteUrl.prices} style={{ color: "inherit" }} relative="path">ЦІНИ</Link></div>
                <div className="header-link"><Link to={prefix + RouteUrl.offers} style={{ color: "inherit" }} relative="path">АКЦІЇ</Link></div>
                <div className="header-link"><Link to={prefix + RouteUrl.contacts} style={{ color: "inherit" }} relative="path">КОНТАКТИ</Link></div>
            </div>
            <div className="header-social-media">
                {socialmedia &&
                    socialmedia.map(media => (<Link to={media.link} style={{ margin: "10px" }} key={"header-" + media.name} target="_blank">
                        <img src={media.icon} style={{ filter: "brightness(0) invert(1)" }} />
                    </Link>))}
            </div>
        </div>
    );
};

export default MenuHeaderDesktop;