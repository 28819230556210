import React, { useState, useEffect, useContext } from 'react';
import { API_URL } from '../../index';
import { ContactContext } from '../App';
import axios from 'axios';

import Footer from '../PageComponents/Footer';
import Header from '../PageComponents/Header';
import RoadDirection from "../Misc/roadDirection";
import ErrorWrapper from "../PageComponents/ErrorWrapper";
import Map from "../PageComponents/Map";

import "./Page.css";

const URL = "pages/contacts/";
const roadDirectionsUrl = "road-directions/"

const ContactsPage = () => {
    const [pageData, setPageData] = useState({});
    const { addresses, phones, workhours, socialmedia } = useContext(ContactContext);
    const [error, setError] = useState(false);
    const [data, setData] = useState({});
    const path = [{ link: "", title: "Контакти" }];

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(API_URL + URL).then(res => {
            setPageData(res.data);
        }).catch(err => setError(true));
    }, []);

    useEffect(() => {
        axios.get(API_URL + roadDirectionsUrl).then(res => {
            const contactData = {
                phones,
                addresses,
                workhours,
                roadDirections: res.data
            }
            setData(contactData);
        }).catch(err => null)
    }, []);

    return (
        <ErrorWrapper error={error}>
            <Header path={path} data={pageData} />
            <div className="frame last">
                <Map fullData />
                <div className="contacts-container">
                    <div className="contacts-data-container">
                        <div className="contacts-section">
                            <h4 className="footer-header" style={{ textAlign: "left" }}>ЯК ДОБРАТИСЯ?</h4>
                            <div className="contacts-data-outer">
                                <div className="contacts-text">
                                    {data.roadDirections &&
                                        data.roadDirections.map(direction => (
                                            <RoadDirection key={"direction" + direction.pk} text={direction.directions} title={direction.title} icon={direction.icon} />
                                        ))}
                                </div>
                            </div>
                        </div>
                        <div className="contacts-section">
                            <h4 className="footer-header" style={{ textAlign: "left" }}>РЕЖИМ РОБОТИ</h4>
                            {workhours &&
                                workhours.map(workhour => (
                                    <div key={"workhours" + workhour.pk} style={{ display: "flex" }}>
                                        <div className="workhours-day">{workhour.title}</div><div className="workhours-time">{workhour.time}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className="contacts-photo-container">
                        <div className="contacts-photo"><img src={pageData.page_picture1} width="100%" style={{ borderRadius: "10px" }} /></div>
                    </div>
                </div>
            </div>
            <Footer nested="1" />
        </ErrorWrapper>
    );
}

export default ContactsPage;
