import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { API_URL, prepareImages, phoneToCall } from '../../index';
import { ContactContext } from '../App';
import axios from 'axios';
import * as DOMPurify from 'dompurify';
import "../FullPages//Page.css";

const emailsUrl = "emails/"


const FullContactsData = () => {
    const [emails, setEmails] = useState([]);
    const { addresses, phones, workhours, socialmedia } = useContext(ContactContext);

    useEffect(() => {
        axios.get(API_URL + emailsUrl).then(res => setEmails(res.data)).catch(err => null)
        },  []);

    return (
        <>
            <div className="contacts-section">
                <h4 className="footer-header" style={{ textAlign: "left" }}>КОНТАКТИ</h4>
                <div className="contacts-data-outer">
                    <div className="phone1 svg-symbol">&nbsp;</div>
                    <div className="contacts-text">
                        {phones &&
                            phones.map(phone => (
                                <div className="contacts-data" key={"phone" + phone.pk}>
                                    <a href={"tel:" + phoneToCall(phone.number)} style={{ color: "inherit" }}>
                                        {phone.number}
                                    </a>
                                </div>
                            ))}
                    </div>
                </div>
                <div className="contacts-data-outer">
                    <div className="email svg-symbol" style={{ marginTop: "0px" }}>&nbsp;</div>
                    <div className="contacts-text">
                        {emails &&
                            emails.map(email => (
                                <div className="contacts-data" key={"email" + email.pk}>
                                    <Link to='#'
                                        onClick={(e) => {
                                            window.location.href = 'mailto:' + email.email;
                                            e.preventDefault();
                                        }}
                                    >
                                        {email.email}
                                    </Link></div>
                            ))}
                    </div>
                </div>
            </div>
            <div className="contacts-section">
                <h4 className="footer-header" style={{ textAlign: "left" }}>АДРЕСА</h4>
                <div className="contacts-data-outer">
                    <div className="address-image svg-symbol">&nbsp;</div>
                    <div className="contacts-text">
                        {addresses &&
                            addresses.map(address => (
                                <a href={"http://maps.google.com/?q=" + address.address.replace(/<[^]*?>/g, '')} target="_blank">
                                    <div className="contacts-data" key={"address" + address.pk} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(address.address)) }}>
                                    </div>
                                </a>
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
}

const ShortContactsData = () => {
    const { addresses, phones, workhours, socialmedia } = useContext(ContactContext);
    return (
        <div className="contacts-section">
            <h4 className="footer-header" style={{ textAlign: "left" }}>ДЕ МИ?</h4>
            <div className="contacts-data-outer">
                <div className="address-image svg-symbol">&nbsp;</div>
                <div className="contacts-text">
                    {addresses &&
                        addresses.map(address => (
                            <a href={"http://maps.google.com/?q=" + address.address.replace(/<[^]*?>/g, '')} target="_blank">
                                <div className="contacts-data" key={"address" + address.pk} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(address.address)) }}>
                                </div>
                            </a>
                        ))}
                </div>
            </div>
        </div>
    );
}

const Map = ({ fullData }) => {
    return (
        <div className="contacts-container">
            <div className="contacts-data-container">
            {fullData ? (<FullContactsData />) : (<ShortContactsData />)}
            </div>
            <div className="contacts-photo-container">
                <iframe className="contacts-photo"
                    loading="lazy"
                    allowFullScreen
                    referrerPolicy="no-referrer-when-downgrade"
                    src={'https://www.google.com/maps/embed/v1/place?key=' + process.env.REACT_APP_API_KEY + '+"Verytas"'}>
                </iframe>
            </div>
        </div>
    );
}

export default Map;