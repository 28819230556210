import './FieldsPreview.css';
import { Link } from "react-router-dom";
import RouteUrl from '../RouteEnum';

const FieldsPreview = ({ field }) => {
    return (
        <Link to={"../" + RouteUrl.medicalFields + (field.url_address ? field.url_address : field.pk)} relative="path">
            <div className="preview" >
                <img src={field.icon} />
                <h4 style={{ margin: "10px" }}>{field.title1.split('|')[0]}</h4>
            </div>
        </Link>
    );
};

export default FieldsPreview;