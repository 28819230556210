import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import { preciseDiff } from 'moment-precise-range-plugin';

const TimerPart = ({ value, desc }) => {
    return (
        <>
            <div className="timer-number">{value}</div><div className="timer-desc">{desc}</div><div style={{ width: "50px" }}>&nbsp;</div>
        </>
    );
}

const Timer = () => {
    const timediff = () => moment.preciseDiff(moment(), moment("2003-01-10 08:00:00"), true);
    const [time, setTime] = useState(timediff());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(timediff());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (<div style={{
        display: "flex", justifyContent: "center", marginBottom: "30px", marginTop: "20px", padding: "0"
    }}>
        <TimerPart value={time.years} desc="Р" />
        <TimerPart value={time.months} desc="М" />
        <TimerPart value={time.days} desc="Д" />
        <TimerPart value={time.hours} desc="Г" />
        <TimerPart value={time.minutes} desc="Х" />
        <TimerPart value={time.seconds} desc="С" />
    </div>);
}

export default Timer;