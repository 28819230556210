import axios from 'axios';
import { API_URL, CACHE_TIME } from '../../index';
import DoctorFull from './DoctorFull';
import { useQuery } from 'react-query';

const doctorsURL = 'doctors/';

const DoctorsFullList = () => {
    const doctors = useQuery('doctors', () => axios.get(API_URL + doctorsURL).then(response => response.data), { cacheTime: CACHE_TIME });

    return (
        <div>
            {!doctors || !doctors.data || doctors.data.length <= 0 ? (
                <div>Nothing yet</div>
            ) :
                doctors.data.map(doctor => (<DoctorFull doctor={doctor} key={"doctor" + doctor.pk} />))
            }
        </div>
    );
}

export default DoctorsFullList;