import './Blog.css';
import { Link } from "react-router-dom";
import RouteUrl from '../RouteEnum';

const BlogPreview = ({ blog }) => {
    return (
        <div className="blog-item-container">
            <div><img src={blog.image_preview} className="blog-preview-image" alt={blog.url_address} /></div>
            <div className="data-blog-item">
                <h1> {blog.title.toUpperCase().split('|')[0]}</h1>
                <div className="blog-preview-text">{blog.text.substring(0, 700).replace(/<h1>.*?h1>/g, '').replace(/<p>/g, ' ').replace(/<.*?>/g, '') + "..."}</div>
            </div>
            <div className="blog-button-div"><Link to={"../" + RouteUrl.blog + (blog.url_address ? blog.url_address : blog.pk)} relative="path">
                <button className="blog-preview-button">ЧИТАТИ&nbsp;&nbsp;&nbsp;&rarr;</button>
            </Link></div>
        </div>        
    );
}

export default BlogPreview;