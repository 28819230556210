import './IndexStyle.css';

const FULL_STAR = "★";
const EMPTY_STAR = "☆";

const Review = ({ name, image, rating, text }) => {
    const getStar = (rating) => {
        const result = FULL_STAR.repeat(Math.ceil(rating)) + EMPTY_STAR.repeat(5 - Math.floor(rating));
        return result;
    }

    return (
        <div className="review-container">
            <div className="review-header"><img src={image} className="review-image" /><h2>{name}</h2></div>
            <div className="review-rating">{getStar(Number(rating))}</div>
            <div className="review-text">{text}</div>
        </div>
    );
}

export default Review;