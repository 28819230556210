import { Children, useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';

const NEXT = 'NEXT';
const PREV = 'PREV';

const getOrder = (index, pos, numItems) => {
    return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos;
};

const Swiper = ({ children }) => {
    const numItems = Children.count(children);
    const [state, setState] = useState({});

    useEffect(() => {
        setState({
            pos: Children.count(children) - 1,
            sliding: false,
            dir: NEXT
        });
    },[children]);

    const slide = (dir) => {
        let newPos = numItems - 1;
        if (dir === NEXT) {
            newPos = state.pos === numItems - 1 ? 0 : state.pos + 1;
            setState({
                dir: NEXT,
                sliding: true,
                pos: newPos
            });
        }
        else if (dir === PREV) {
            newPos = state.pos === 0 ? numItems - 1 : state.pos - 1;
            setState({
                dir: PREV,
                sliding: true,
                pos: newPos
            });
        }
        setTimeout(() => {
            setState({
                dir: state.dir,
                sliding: false,
                pos: newPos
            });
        }, 50);
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => slide(NEXT),
        onSwipedRight: () => slide(PREV),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true
    });

    return (
        <div {...handlers} className="swiper-container">
            <div style={{
                width: '100%',
                overflow: 'hidden'
            }}>
                <div style={{
                    display: "flex",
                }}>
                    {Children.map(children, (child, index) => (
                        <div style={{flex: '1 0 100%',
                            flexBasis: '350px',
                            marginRight: '0px',
                            order: `${getOrder(index, state.pos, numItems)}`
                        }} >
                            {child}
                        </div>
                    ))}
                </div>
            </div>
            <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "center"
            }}>
                <button className="swiper-navigation-btn" onClick={() => slide(PREV)}>&lt;</button>
                <button className="swiper-navigation-btn" onClick={() => slide(NEXT)}>&gt;</button>
            </div>
        </div>
    );
}

export default Swiper;