import axios from "axios";
import { API_URL, CACHE_TIME } from '../../index';
import FieldsPreview from './FieldsPreview';
import { useQuery } from 'react-query';

const fieldsURL = "medical-fields/";

const FieldsList = () => {
    const fields = useQuery('medical-fields', () => axios.get(API_URL + fieldsURL).then(response => response.data), { cacheTime: CACHE_TIME });

    return (
        <div className="preview-list">
            {!fields || !fields.data || fields.data.length <= 0 ? (<></>) :
                fields.data.sort((a, b) => a.title1.toUpperCase() > b.title1.toUpperCase() ? 1 : -1).map(field => (<FieldsPreview field={field} key={"medicalfield"+field.pk} />
                ))
            }
        </div>
    );
};

export default FieldsList;