import { useQuery } from 'react-query';
import Review from '../IndexComponents/Review';
import Swiper from '../IndexComponents/Swiper';
import { API_URL, CACHE_TIME} from '../../index';
import axios from 'axios';

const reviewsURL = "google-reviews/"

const ReviewSwiper = () => {
    const reviews = useQuery('reviews', () => axios.get(API_URL + reviewsURL).then(
        response => JSON.parse(response.data)), { cacheTime: CACHE_TIME });

    return (
        <>
            <h1 className="main-header">ВІДГУКИ ПРО НАС</h1>
            <div style={{ width: "98%", display: "flex", justifyContent: "flex-start", overflow: "hidden" }}>
            <Swiper>
                {reviews && reviews.data && reviews.data.map((review, index) => (<Review key={'review' + index} name={review.author_name} image={review.profile_photo_url} rating={review.rating} text={review.text} />))}
            </Swiper>
            </div>
        </>
    )
}

export default ReviewSwiper;