import { useQuery } from 'react-query';
import axios from "axios";
import { API_URL, CACHE_TIME } from '../../index';
import MenuService from './MenuService';

const serviceURL = "filtermenuservices/";

const MenuCategory = ({ category }) => {
    const services = useQuery('menu-service' + category.pk, () => axios.get(API_URL + serviceURL + category.pk).then(response => response.data), { cacheTime: CACHE_TIME });

    return (
        <div>
            <div className="category-title">{category && category.title ? category.title.toUpperCase() : ""}</div>
            <div className="service-container">
                <div className="service-title category-header">&nbsp;</div>
                <div className="service-cost-total category-header">Ціна, грн</div>
            </div>
            <hr className="hr-category" />
            <div style={{ paddingBottom: "20px" }}>
                {!services || !services.data || services.data.length <= 0 ? (
                    <div>Nothing yet</div>
                ) :
                    services.data.map(service => (<MenuService service={service} key={"service"+service.pk} />))
                }
            </div>
        </div>
    );
}

export default MenuCategory;