import React, { useState, useEffect } from 'react';
import { API_URL } from '../../index';
import axios from 'axios';
import Footer from '../PageComponents/Footer';
import Header from '../PageComponents/Header';
import ErrorWrapper from "../PageComponents/ErrorWrapper";


import "./Page.css";
const URL = "pages/licence/";


const LicencePage = () => {
    const [data, setData] = useState({});
    const [error, setError] = useState(false);
    const path = [{ link: "", title: "Ліцензія" }];

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(API_URL + URL).then(res => {
            setData(res.data);
        }).catch(err => setError(true));
    }, []);

    return (
        <ErrorWrapper error={error}>
            <Header path={path} data={data} />
            <div className="frame last">
                <h1 className="main-header">{data.title1 && data.title1.toUpperCase().split('|').join('\n')}</h1>
                <div style={{ display: "flex", justifyContent: "center" }}><img src={data.page_picture1} style={{ maxWidth: "90vw" }} /></div>
            </div>
            <Footer nested="1" />
        </ErrorWrapper>
    );
}

export default LicencePage;