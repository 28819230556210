import React, { useState, useEffect, useContext } from 'react';
import { API_URL, prepareImages } from '../../index';
import axios from 'axios';
import * as DOMPurify from 'dompurify';

import ModalContext from '../MakeAppointmentModal/ModalProvider';
import DoctorsFullList from '../Doctors/DoctorsFullList';
import Footer from '../PageComponents/Footer';
import Header from '../PageComponents/Header';
import ProList from '../Misc/proList';
import ErrorWrapper from "../PageComponents/ErrorWrapper";


import "./Page.css";

const URL = "pages/doctors/";

const DoctorsPage = () => {
    const [data, setData] = useState({});
    const [error, setError] = useState(false);
    const { modalOn, modalData } = useContext(ModalContext);
    const path = [{ link: "", title: "Лікарі" }];

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(API_URL + URL).then(res => {
            setData(res.data);
        }).catch(err => setError(true));
    }, []);

    const onClick = () => {
        modalData("Консультація", "ЗАПИСАТИСЯ НА КОНСУЛЬТАЦІЮ");
        modalOn();
    };

    return (
        <ErrorWrapper error={error}>
            <Header path={path} data={data} />
            <div className="frame last">
                <h1 className="main-header">{data.title1 && data.title1.toUpperCase().split('|').join('\n')}</h1>
                <ProList />
                <div style={{ textAlign: "center" }}><button className="offers-button" onClick={onClick} ><h2 className="h2-button">ЗАПИСАТИСЯ НА КОНСУЛЬТАЦІЮ</h2></button></div>

                <div style={{ fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(data.text1)) }}></div>

                <h1 className="main-header">ЛІКАРІ</h1>
                <DoctorsFullList />

                <div style={{ textAlign: "center" }}><button className="offers-button" onClick={onClick} ><h2 className="h2-button">ЗАПИСАТИСЯ НА КОНСУЛЬТАЦІЮ</h2></button></div>
            </div>
            <Footer nested="1" />
        </ErrorWrapper>
    );
}

export default DoctorsPage;