import PackageOfferList from '../PackageOffers/PackageOfferList';
import { useRef, useEffect, useState } from 'react';
import './IndexStyle.css';

const PACKAGE_WIDTH = 315;

const IndexPackageOffers = () => {
    const ref = useRef(null);
    const [firstRowNumber, setFirstRowNumber] = useState(-1);

    useEffect(() => {
        showPart();
    }, []);

    const showPart = () => {
        const enoughRoom = Math.floor(ref.current.offsetWidth / PACKAGE_WIDTH) - 1;
        setFirstRowNumber(enoughRoom > 0 ? enoughRoom : 1);
    }

    return (
        <div ref={ref}>
            <div><PackageOfferList show={firstRowNumber} /></div>
            <div className={firstRowNumber > 0 ? "" : "hidden"} style={{ textAlign: "center" }}><button className="show-index-button" onClick={() => setFirstRowNumber(-1)}>ПОКАЗАТИ ВСІ &darr;</button></div>
            <div className={firstRowNumber > 0 ? "hidden" : ""} style={{ textAlign: "center" }}><button  className="show-index-button"  onClick={showPart}>СХОВАТИ &uarr;</button></div>
        </div>
    );
}

export default IndexPackageOffers;