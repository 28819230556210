import { useQuery } from 'react-query';
import axios from "axios";
import { API_URL, CACHE_TIME } from '../../index';
import ProceduresPreview from './ProceduresPreview';
import './ProceduresPreview.css';

const proceduresURL = "procedures/";

const ProceduresList = () => {
    const procedures = useQuery('procedures', () => axios.get(API_URL + proceduresURL).then(response => response.data), { cacheTime: CACHE_TIME });

    return (
        <div className="preview-list">
            {!procedures || !procedures.data || procedures.data.length <= 0 ? (<></>) :
                procedures.data.sort((a, b) => a.title_main.toUpperCase() > b.title_main.toUpperCase() ? 1 : -1).map(procedure => (<ProceduresPreview procedure={procedure} key={"procedure"+procedure.pk} />
                ))
            }
            </div>
    );
};

export default ProceduresList;