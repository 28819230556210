import './ProceduresPreview.css';
import { Link } from "react-router-dom";
import RouteUrl from '../RouteEnum';

const ProceduresPreview = ({ procedure }) => {
    return (
        <Link to={"../" + RouteUrl.procedures + (procedure.url_address ? procedure.url_address : procedure.pk)} relative="path">
            <div className="preview" >
                <img src={procedure.icon} />
                <h4 style={{ margin: "10px" }}>{procedure.title_main.split('|')[0]}</h4>
            </div>
        </Link>
    );
};

export default ProceduresPreview;