import './DoctorFull.css';
import ModalContext from '../MakeAppointmentModal/ModalProvider';
import React, { useContext } from 'react';

const DoctorFull = ({ doctor }) => {
    const { modalOn, modalData } = useContext(ModalContext);

    const BUTTON_TEXT = "ЗАПИСАТИСЯ НА КОНСУЛЬТАЦІЮ";
    
    const onClick = () => {
        modalData("Консультація - "+doctor.name, BUTTON_TEXT);
        modalOn();
    };

    return (
        <div className="doctor-container-full hoverable">
            <div className="doctor-presentation-div">
                <img src={doctor.image} className="doctor-image" />
                <h2>{doctor.name}</h2>
                <div className="doctor-field">{doctor.field}</div>
            </div>
            <div className="doctor-data">
                <h4 className="doctor-data-h">Спеціалізація</h4>
                <div>{doctor.specialization}</div>
                <h4 className="doctor-data-h">Досвід медичної практики</h4>
                <div>{doctor.experience}</div>
                <h4 className="doctor-data-h">Освіта</h4>
                <div>{doctor.education}</div>
            </div>
            <div className="doctor-button-div display-on-hover"><button className="doctor-button" onClick={onClick}><h2 className="h2-button borderless">{BUTTON_TEXT}</h2></button></div>
        </div>
    );
}

export default DoctorFull;