import * as DOMPurify from 'dompurify';
import { useContext } from 'react';
import ModalContext from '../MakeAppointmentModal/ModalProvider';
import MenuHeaderMobile from "../PageComponents/MenuHeaderMobile";
import { LOCAL_BUTTON_TEXT, BUTTON_TEXT } from '../FullPages/IndexPage';
import { prepareImages } from '../../index';

import './IndexStyle.css';
import clock from './icons/clock.svg';
import phone from './icons/phone.svg';
import location from './icons/location.svg';

const IndexHeaderMobile = ({ pageData, contacts }) => {
    const { modalOn, modalData } = useContext(ModalContext);

    const phoneToCall = (phone) => {
        const regex = /[()\s\-]/g;
        return phone.replace(regex, '');
    }

    const onClick = () => {
        modalData("Прийом у лікаря", BUTTON_TEXT);
        modalOn();
    };

    return (
        <>
            <div className="borderless" style={{ minHeight: "calc(100vh - 85px)", width: "100%" }}></div>
            <div style={{ height: "calc(100vh - 50px)", minWidth: "100vw", maxWidth: "100vw", overflow: "hidden", position: "absolute", top: "0" }}>
                <div className="index-header borderless" style={{ height: "calc(100vh - 85px)", minWidth: "100vw", maxWidth: "100vw" }}>
                    <div className="header" style={{ height: "calc(100vh - 85px)", display: "flex", flexDirection: "column", position: "relative", minWidth: "100vw", maxWidth: "100vw" }}>
                        <div style={{ width: '100%', minHeight: '20vh', overflow: "hidden"}}></div>
                        <MenuHeaderMobile transparent index nested="0" />
                        <div style={{ padding: "20px", height: "calc(80vh - 75px)", display: "flex", flexDirection: "column", overflow: "hidden" }}>
                            <h1 className="index-header-title" style={{fontSize: "28px" }}>{pageData.title1}</h1>
                            <div className="index-header-plaintext " dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(pageData.text1)) }}></div>
                            <div style={{ display: "flex", justifyContent: "center", width: "100%", position: "absolute", bottom: "-5px" }}><img src={pageData.image} style={{ maxWidth: "100%", maxHeight: "50vh" }} /></div>
                        </div>
                        <div style={{
                            position: "absolute",
                            justifyContent: "center",
                            display: "flex",
                            bottom: "-25px",
                            width: "100%",
                            overflow: "hidden"
                        }}><button className="index-header-button" onClick={onClick}>{LOCAL_BUTTON_TEXT}</button></div>
                    </div>
                </div>
            </div>
            <div className="frame" style={{ maxWidth: "100%", overflow: "hidden" }}>
                <div className="index-contacts-block">
                    <div className="index-contacts-container">
                        <div className="index-contacts-icon"><img src={clock} /></div>
                        <div className="index-contacts-text">
                            {contacts.workhours && contacts.workhours.map(item => (
                                <div key={"index-workhours-" + item.pk} style={{ display: "inline-block", width: "130px" }}>
                                    <div style={{ fontWeight: "700", float: "left" }}>{item.title}</div><div style={{ float: "right" }}>{item.time}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="index-contacts-container">
                        <div className="index-contacts-icon"><img src={phone} /></div>
                        <div className="index-contacts-text">
                            <div style={{ display: "flex", flexWrap: "wrap", width: "calc(100% - 20px)", gap: "0px 20px" }}>
                                {contacts.phones && contacts.phones.map(item => (
                                    <div key={"index-phones-" + item.pk}>
                                        <a href={"tel:" + phoneToCall(item.number)} style={{ color: "inherit" }}>{item.number.replace("+38 ", "")}</a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="index-contacts-container">
                        <div className="index-contacts-icon"><img src={location} /></div>
                        <div className="index-contacts-text">
                            {contacts.addresses && contacts.addresses.map(item => (
                                <a href={"http://maps.google.com/?q=" + item.address.replace(/<[^]*?>/g, '')} target="_blank">
                                    <div key={"index-addresses-" + item.pk}
                                        style={{ display: "inline-block", width: "calc(100% - 20px)" }}
                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(item.address)) }} />
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
        );
}

export default IndexHeaderMobile;