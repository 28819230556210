import React, { useState, useEffect } from 'react';
import axios from "axios";
import { API_URL } from '../../index';
import MenuField from './MenuField';
import './MenuViewManager.css';

const menufieldURL = "menufields/";

const MenuViewManager = () => {
    const [menufields, setMenufields] = useState([]);

    useEffect(() => {
        getMenufields();
    }, []);

    const getMenufields = () => {
        axios.get(API_URL + menufieldURL).then(data => setMenufields(data.data));
    };

    return (
        <div>
            {!menufields || menufields.length <= 0 ? (
                <div>Nothing yet</div>
            ) :
                menufields.map(menufield => (<MenuField field={menufield} key={"menufield"+menufield.pk} />))
            }
        </div>
    );
}

export default MenuViewManager;