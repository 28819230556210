import "./MenuHeader.css";
import RouteUrl from '../RouteEnum';
import { Link } from "react-router-dom";
import { useContext } from 'react';
import { ContactContext } from '../App';
import ModalContext from '../MakeAppointmentModal/ModalProvider';


const MenuMobile = ({ nested, close, index }) => {
    const context = useContext(ContactContext);
    const socialmedia = context.socialmedia;
    const { modalOn, modalData } = useContext(ModalContext);
    const prefix = "../".repeat(nested);
    const BUTTON_TEXT = "ЗАПИСАТИСЯ НА КОНСУЛЬТАЦІЮ";

    const onClick = () => {
        modalData("Консультація", BUTTON_TEXT);
        modalOn();
    };

    const indexStyleHeader = {
        height: '20vh',
    };

    const indexStyleMenu = {
        height: 'calc(80vh - 200px)',
        top: '20vh'
    };

    return (
        <div className="mobile-menu-container">
            <div className="mobile-desktop-container" style={{ background: "transparent" }} style={index && indexStyleHeader}>
                <Link to={RouteUrl.index} style={{ color: "inherit" }} relative="path"><div className="mobile-logo header-logo">&nbsp;</div></Link>
                <div style={{ width: "100%" }}></div>
                <div className="mobile-menu-icon"><button className="open-header-menu" onClick={close} style={{ fontSize: "40px" }}>&#x2715;</button></div>
            </div>
            <div className="header-mobile-menu" style={index && indexStyleMenu}>
                <Link to={prefix + RouteUrl.medicalFields} style={{ color: "inherit" }} relative="path"><div className="header-link">НАПРЯМКИ</div></Link>
                <Link to={prefix + RouteUrl.doctors} style={{ color: "inherit" }} relative="path"><div className="header-link">ЛІКАРІ</div></Link>
                <Link to={prefix + RouteUrl.procedures} style={{ color: "inherit" }} relative="path"><div className="header-link">МЕТОДИКИ ТА ПРОЦЕДУРИ</div></Link>
                <Link to={prefix + RouteUrl.prices} style={{ color: "inherit" }} relative="path"><div className="header-link">ЦІНИ</div></Link>
                <Link to={prefix + RouteUrl.offers} style={{ color: "inherit" }} relative="path"><div className="header-link">АКЦІЇ</div></Link >
                <Link to={prefix + RouteUrl.contacts} style={{ color: "inherit" }} relative="path"><div className="header-link">КОНТАКТИ</div></Link >
            
                <div className="header-social-media">
                    {socialmedia &&
                        socialmedia.map(media => (<Link to={media.link} style={{ margin: "10px" }} key={"header-" + media.name} target="_blank">
                            <img src={media.icon} alt={ media.name } style={{ filter: "brightness(0) invert(1)" }} />
                        </Link>))}
                </div>
            </div>
            <div className="menu-schedule-div"><button className="menu-schedule-button" style={{zIndex: "52"}} onClick={ onClick }>ЗАПИСАТИСЯ НА ПРИЙОМ&nbsp;&nbsp;&rarr;</button></div>
        </div>
    );
}

export default MenuMobile;
