import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { useParams } from 'react-router';
import './ProcedureDetailed.css';
import { API_URL, prepareImages } from '../../index';
import * as DOMPurify from 'dompurify';
import ModalContext from '../MakeAppointmentModal/ModalProvider';
import Footer from '../PageComponents/Footer';
import Header from '../PageComponents/Header';
import ErrorWrapper from "../PageComponents/ErrorWrapper";
import ReviewSwiper from '../PageComponents/ReviewSwiper';
import Map from "../PageComponents/Map";

import RouteUrl from '../RouteEnum';

const proceduresURL = "procedures/";

const ProcedureDetailed = () => {
    const { id } = useParams();
    const [procedure, setProcedure] = useState([]);
    const [headerData, setHeaderData] = useState({});
    const { modalOn, modalData } = useContext(ModalContext);
    const [error, setError] = useState(false);

    const BUTTON_TEXT = "ЗАПИСАТИСЯ НА КОНСУЛЬТАЦІЮ";
    const path = [{ link: "../" + RouteUrl.procedures, title: "Методики і процедури" }, { link: "", title: procedure.title_main }]

    const onClick = () => {
        modalData(procedure.title_main, BUTTON_TEXT);
        modalOn();
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getProcedure();
    }, []);

    const getProcedure = () => {
        axios.get(API_URL + proceduresURL + id).then(data => {
            setProcedure(data.data);
            setHeaderData({
                image: data.data.image,
                SEO_title: data.data.SEO_title,
                SEO_keywords: data.data.SEO_keywords,
                SEO_desc: data.data.SEO_desc
            });
        }).catch(err => setError(true));
    };

    return (
        <ErrorWrapper error={error}>
            <Header path={path} data={headerData} />
            <div className="frame">
                {procedure.title_main && <h1 className="main-header">{procedure.title_main.toUpperCase().split('|').join('\n')}</h1>}
                <div className="procedure-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(procedure.text_main)) }}></div>
                <ReviewSwiper />
                {procedure.title_middle && <h1 className="main-header">{procedure.title_middle.toUpperCase().split('|').join('\n')}</h1>}
                <div className="procedure-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(procedure.text_middle)) }}></div>
                <div className="procedure indication">
                    <h1 className="main-header">ПОКАЗАННЯ ДО ЗАСТОСУВАННЯ</h1>
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(procedure.indication)) }}></div>
                </div>
                <div className="procedure contraindication">
                    <h1 className="main-header">ПРОТИПОКАЗАННЯ</h1>
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(procedure.contraindication)) }}></div>
                </div>
                {procedure.title_last && <h1 className="main-header">{procedure.title_last.toUpperCase().split('|').join('\n')}</h1>}
                <div className="procedure-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(procedure.text_last)) }}></div>
                <Map />
                <div style={{ height: "65px" }}>&nbsp;</div>
                <div className="procedure-button-div"><button className="procedure-button" onClick={onClick} >
                    <div className="field-button-content">
                        <div className="message-svg">&nbsp;</div><h2 className="borderless" style={{ fontSize: "14px", textAlign: "left" }}>{BUTTON_TEXT}</h2>
                    </div>
                </button></div>
            </div>
            <Footer nested="2" />
        </ErrorWrapper>
    );
}

export default ProcedureDetailed;