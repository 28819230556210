import React, { useContext } from 'react';
import ModalContext from '../MakeAppointmentModal/ModalProvider';

const MenuService = ({ service }) => {
    const { modalOn, modalData } = useContext(ModalContext);
    const BUTTON_TEXT = "ЗАПИСАТИСЯ";

    const onClick = () => {
        modalData(service.title, BUTTON_TEXT);
        modalOn();
    };

    return (
        <>
            <div className="service-container hoverable">
                <div className="service-title">{service.title}</div>
                <div className="service-cost-total">
                    <div className="service-cost">{service.cost}</div>
                    <div className="service-button display-on-hover"><button className="service-button" onClick={onClick}>{BUTTON_TEXT}</button></div>
                </div>
            </div>
            <hr className="hr-service" />
        </>
    );
};

export default MenuService;