import React, { useState, useEffect, useContext} from 'react';
import { API_URL } from '../../index';
import axios from 'axios';

import ModalContext from '../MakeAppointmentModal/ModalProvider';
import PackageOfferList from '../PackageOffers/PackageOfferList';
import SaleOfferList from '../SaleOffers/SaleOfferList';
import Footer from '../PageComponents/Footer';
import Header from '../PageComponents/Header';
import ErrorWrapper from "../PageComponents/ErrorWrapper";
import Map from "../PageComponents/Map";


import "./Page.css";

const URL = "pages/offers/";

const OffersPage = () => {
    const [data, setData] = useState({});
    const [error, setError] = useState(false);
    const { modalOn, modalData } = useContext(ModalContext);
    const path = [{ link: "", title: "Акції" }];

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(API_URL + URL).then(res => {
            setData(res.data);
        }).catch(err => setError(true));
    }, []);

    const onClick = () => {
        modalData("Прийом у лікаря", "ЗАПИСАТИСЯ НА ПРИЙОМ");
        modalOn();
    };

    return (
        <ErrorWrapper error={error}>
            <Header path={path} data={ data } />
            <div className="frame">
                <h1 className="main-header">АКЦІЙНІ ПРОПОЗИЦІЇ</h1>
                <SaleOfferList />

                <h1 className="main-header">ПАКЕТНІ ПРОПОЗИЦІЇ</h1>
                <PackageOfferList show="-1" />

                <div style={{ textAlign: "center" }}><button className="offers-button" onClick={onClick} ><h2 className="h2-button">ЗАПИСАТИСЯ НА ПРИЙОМ</h2></button></div>
                <Map />
            </div>
            <Footer nested="1" />
        </ErrorWrapper>
    );
}

export default OffersPage;