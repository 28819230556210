import './MenuHeader.css';
import MenuMobile from './MenuMobile';
import { useState, useEffect } from "react";
import RouteUrl from '../RouteEnum';
import { Link } from "react-router-dom";
import menuSymbol from './menuSymbol.svg';

const HIDDEN = "hidden";

const MenuHeaderMobile = ({ nested, transparent, index }) => {
    const [hiddenStr, setHiddenStr] = useState(HIDDEN);

    const onClick = () => {
        setHiddenStr("");
    }

    const closeMenu = () => {
        setHiddenStr(HIDDEN);
    }

    const indexStyle = {
        height: '20vh'
    };

    return (
        <>
            <div className={transparent ? "mobile-desktop-container" : "mobile-desktop-container with-bg"} style={index && indexStyle}>
                {nested === 0 ? (<div className="mobile-logo header-logo">&nbsp;</div>) :
                    (<Link to={RouteUrl.index} style={{ color: "inherit" }} relative="path"><div className="mobile-logo header-logo">&nbsp;</div></Link>)}
                <div style={{ width: "100%" }}></div>
                <div className="mobile-menu-icon"><button className="open-header-menu" onClick={onClick} ><img src={menuSymbol} height="30px" alt="menuSymbol" /></button></div>
            
            </div>
            <div className={hiddenStr}><MenuMobile nested={nested} close={closeMenu} index /></div>
        </>
    );
};

export default MenuHeaderMobile;

// <div className="mobile-menu-icon"><button className="open-header-menu" onClick={onClick} >&equiv;</button></div>