import { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { useParams } from 'react-router';
import { API_URL, prepareImages } from '../../index';
import * as DOMPurify from 'dompurify';
import Footer from '../PageComponents/Footer';
import Header from '../PageComponents/Header';
import ErrorWrapper from "../PageComponents/ErrorWrapper";
import ModalContext from '../MakeAppointmentModal/ModalProvider';
import ReviewSwiper from '../PageComponents/ReviewSwiper';
import Map from "../PageComponents/Map";


const URL = "pages/";

const MiscPage = ({ ancestorPath }) => {
    const { id } = useParams();
    const [data, setData] = useState({});
    const [error, setError] = useState(false);
    const [path, setPath] = useState([{ link: "", title: "" }]);
    const { modalOn, modalData } = useContext(ModalContext);

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(API_URL + URL+id).then(res => {
            setData(res.data);
            console.log(res.data);
            ancestorPath.push({ link: "", title: res.data.title1 })
            setPath(ancestorPath);
        }).catch(err => setError(true));
    }, []);

    const BUTTON_TEXT = "ЗАПИСАТИСЯ НА КОНСУЛЬТАЦІЮ";

    const onClick = () => {
        modalData(data.title1, BUTTON_TEXT);
        modalOn();
    };

    const addMainHeader = (text) => {
        return text && text.replace(/<h1>/g, '<h1 class="main-header">')
    }

    return (
        //<ErrorWrapper error={error}>
        <>
            {console.log(ancestorPath)}
            <Header path={path} data={data} />
            <div className="frame">
                {data.title1 && <h1 className="main-header">{data.title1.toUpperCase().split('|').join('\n')}</h1>}
                <div className="procedure-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(addMainHeader(prepareImages(data.text1))) }}></div>
                <ReviewSwiper />
                {data.title2 && <h1 className="main-header">{data.title2.toUpperCase().split('|').join('\n')}</h1>}
                <div className="procedure-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(addMainHeader(prepareImages(data.text2))) }}></div>
                {data.title3 && <h1 className="main-header">{data.title3.toUpperCase().split('|').join('\n')}</h1>}
                <div className="procedure-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(addMainHeader(prepareImages(data.text3))) }}></div>     
                <Map />
                <div style={{ height: "65px" }}>&nbsp;</div>
                <div className="field-button-div"><button className="field-button" onClick={onClick} >
                    <div className="field-button-content">
                        <div className="message-svg">&nbsp;</div><h2 className="borderless" style={{ fontSize: "14px", textAlign: "left" }}>{BUTTON_TEXT}</h2>
                    </div>
                </button></div>
            </div>
            <Footer nested={path.length} /></>
        //</ErrorWrapper >
    );
}

export default MiscPage;