import Error404Page from "../FullPages/Error404Page";

const ErrorWrapper = ({ error, children }) => {
    return (
        <>
            {error ? (<Error404Page />) : children}
        </>
    )
}

export default ErrorWrapper;