import React, { useState, useEffect } from 'react';
import { API_URL, prepareImages } from '../../index';
import axios from 'axios';
import * as DOMPurify from 'dompurify';

import Footer from '../PageComponents/Footer';
import Header from '../PageComponents/Header';
import ErrorWrapper from "../PageComponents/ErrorWrapper";


import "./Page.css";
const URL = "pages/gift/";


const GiftVoucherPage = () => {
    const [data, setData] = useState({});
    const [error, setError] = useState(false);
    const path = [{ link: "", title: "Подарунковий сертифікат" }];

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(API_URL + URL).then(res => {
            setData(res.data);
        }).catch(err => setError(true));
    }, []);

    return (
        <ErrorWrapper error={error}>
            <Header path={path} data={data} />
            <div className="frame last">
                <h1 className="main-header">{data.title1 && data.title1.toUpperCase().split('|').join('\n')}</h1>
                <div className="" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(data.text1)) }}></div>
                <div className="important-caveat" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(data.text2)) }}></div>
            </div>
            <Footer nested="1" />
        </ErrorWrapper>
    );
}

export default GiftVoucherPage;