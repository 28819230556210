import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import axios from "axios";
import { API_URL, CACHE_TIME } from '../../index';
import MenuCategory from './MenuCategory';

const categoryURL = "filtermenucategories/";

const MenuField = ({ field }) => {
    const [active, setActive] = useState(false);
    const categories = useQuery('menu-category' + field.pk, () => axios.get(API_URL + categoryURL + field.pk).then(response => response.data), { cacheTime: CACHE_TIME });

    useEffect(() => { }, [active]);

    const openMenu = () => {
        setActive(true);
    }

    const closeMenu = () => {
        setActive(false);
    }

    const openButton = (<button className="title-button title-close" onClick={openMenu}>&#8964;</button>);

    const closeButton = (<button className="title-button title-open" onClick={closeMenu}>&#8963;</button>);

    return (
        <div>
            <div className={active ? "open-field-title menu-field-title" : "closed-field-title menu-field-title"} onClick={active ? () => { } : openMenu}>
                <div className={active ? "menu-open menu-title-text" : "menu-closed menu-title-text"}>{field && field.title ? field.title.toUpperCase() : ""}</div>
                <div className="menu-title-button">{active ? closeButton : openButton}</div>
            </div>
            {active &&
                (<div className="categories-in-field">
                {!categories || !categories.data || categories.data.length <= 0 ? (
                    <div>Nothing yet</div>
                ) :
                    categories.data.map(category => (<MenuCategory category={category} key={"category"+category.pk} />))
                }
            </div>)}
        </div>
        );
}

export default MenuField;