import "./Page.css";
import React, { useState, useEffect } from 'react';
import { API_URL } from '../../index';
import axios from 'axios'
import Footer from '../PageComponents/Footer';
import Header from '../PageComponents/Header';
import BlogList from '../Blog/BlogList';
import ErrorWrapper from "../PageComponents/ErrorWrapper";

const URL = "pages/blog/";

const BlogPage = () => {
    const [data, setData] = useState({});
    const [error, setError] = useState(false);
    const path = [{ link: "", title: "Блог" }];

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(API_URL + URL).then(res => {
            setData(res.data);
        }).catch(err => setError(true));
    }, []);

    return (
        <ErrorWrapper error={error}>
            <Header path={path} data={data} />
            <div className="frame last">
                <h1 className="main-header">Блог</h1>
                <BlogList />
            </div>
            <Footer nested="1" />
        </ErrorWrapper>
    );
}

export default BlogPage;