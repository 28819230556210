import './pro.css';
import saveTime from './saveTime.svg';
import contactless from './contactless.svg';
import result from './result.svg';

const ProList = () => {
    return (
        <div className="pros-list-container">
            <div className="pro-container">
                <div><img src={saveTime} className="pro-image" alt="saveTime" /></div>
                <div className="pro-text">Економія часу</div>
            </div>
            <div className="pro-container">
                <div><img src={contactless} className="pro-image" alt="contactless" /></div>
                <div className="pro-text">Відсутність зайвих контактів</div>
            </div>
            <div className="pro-container">
                <div><img src={result} className="pro-image" alt="result" /></div>
                <div className="pro-text">Заключення в онлайн-кабінеті</div>
            </div>
        </div>
    );
}

export default ProList;