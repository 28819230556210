import './PackageOffer.css';
import * as DOMPurify from 'dompurify';
import { prepareImages } from '../../index';

const PackageOffer = ({ offer }) => {
	return (
		<div className="outer-box">
			<h1>{offer.title.toUpperCase()}</h1>
			<div className="description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(offer.text)) }}></div>
			<div className = "old-cost">{offer.old_cost}</div>
			<div className="new-cost">{offer.cost}</div>
			<div className="caveat">{offer.caveat}</div>
		</div>
		);
};

export default PackageOffer;