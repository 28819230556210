import { Link } from "react-router-dom";
import RouteUrl from '../RouteEnum';
import './Page.css';

const Error404Page = () => {
    return (
        <div className="frame">
            <h1 className="main-header">Error 404</h1><div>Сторінку не знайдено</div><Link to={RouteUrl.index} >На головну</Link>
        </div>
    );
}

export default Error404Page;