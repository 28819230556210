import React, { useContext } from 'react';
import ModalContext from '../MakeAppointmentModal/ModalProvider';
import "./SaleOffer.css";
import * as DOMPurify from 'dompurify';
import useWidth from '../../hooks/useWidth';
import { prepareImages } from '../../index';


const SaleOffer = ({ offer }) => {
    const { modalOn, modalData } = useContext(ModalContext);
    const width = useWidth();

    const BUTTON_TEXT = "ЗАПИСАТИСЯ";

    const onClick = () => {
        modalData(offer.title, BUTTON_TEXT);
        modalOn();
    };

    return (
        <div className="sale-outer-box">
            <div><img src={
                width > 900 ? offer.image_desktop : offer.image_mobile
            } className="sale-offer-image" alt="offer" /></div>
            <div className="data-sale-offer">
                <h1> {offer.title.toUpperCase()}</h1>
                <div className="description sale-description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(offer.text)) }}></div>
                <div className="old-cost-sale">{offer.old_cost}</div>
                <div className="new-cost-sale">{offer.cost}</div>
            </div>
            <div className="sale-button-div"><button className="sale-button" onClick={onClick} >СКОРИСТАТИСЯ АКЦІЄЮ&nbsp;&nbsp;&nbsp;&rarr;</button></div>
        </div>
        );
};

export default SaleOffer;