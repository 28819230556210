import MenuHeaderDesktop from "./MenuHeaderDesktop";
import MenuHeaderMobile from "./MenuHeaderMobile";
import { Link } from "react-router-dom";
import { Fragment } from 'react';
import "./Header.css";
import RouteUrl from '../RouteEnum';
import { Helmet } from 'react-helmet-async';
import useWidth from '../../hooks/useWidth';
import { sanitize } from '../../index';


const Header = ({ data, path }) => {
    const width = useWidth();

    return (
        <>
            <Helmet>
                {data.SEO_title && (<title>{sanitize(data.SEO_title)}</title>)}
                {data.SEO_desc && (<meta name="description" content={sanitize(data.SEO_desc)} data-react-helmet="true" />)}
                {data.SEO_keywords && (<meta name="keywords" content={sanitize(data.SEO_keywords)} data-react-helmet="true" />)}
                {data.SEO_title && (<meta property="og:title" content={sanitize(data.SEO_title)} data-react-helmet="true" />)}
                {data.SEO_desc && (<meta property="og:description" content={sanitize(data.SEO_desc)} data-react-helmet="true" />)}
            </Helmet>
            <div className="header-padding"></div>
            <div className="header">
                {width > 700 ? (<MenuHeaderDesktop nested={path.length} />) : (<MenuHeaderMobile nested={path.length} />)}
                <div className="frame">
                <img src={data.image} className="header-picture" />
                    <div className="path">
                        <Link to={RouteUrl.index} style={{ color: "inherit" }}>Головна</Link>
                        {path.length>0 && path.map(item => (<Fragment key={"path-" + item.title}>&nbsp;&gt;&nbsp;<Link to={item.link} style={{ color: "inherit" }} >{item.title}</Link></Fragment>))}
                    </div>
                </div>
            </div>
        </>
        );
};

export default Header;