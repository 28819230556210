import * as DOMPurify from 'dompurify';
import './roadDirection.css';
import { prepareImages } from '../../index';

const RoadDirection = ({ icon, text, title }) => {
    return (
        <div>
            <div className="road-container">
                <div className="symbol"><img src={icon} /></div> <h4 style={{ marginTop: "7px" }}>{title}</h4>
            </div>
            <div className="road-data contacts-data" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(text)) }}></div>
        </div>
    );
}

export default RoadDirection;