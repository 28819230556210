
const RouteUrl = {
    index: "/",
    initRedirect: "uk/",
    offers: "offers/",
    prices: "prices/",
    contacts: "contacts/",
    doctors: "doctors/",
    procedures: "procedures/",
    medicalFields: "medical-fields/",
    licence: "licence/",
    giftVoucher: "gift-voucher/",
    blog: "blog/",
    massages: "massages/"
};

export default RouteUrl;