import React, {useState, useEffect} from 'react';
import MakeAppointmentModal from './MakeAppointmentModal';
import { ModalProvider } from './ModalProvider';
import { useLocation } from 'react-router-dom';

const ModalWrapper = ({ children }) => {
    const [visible, setVisible] = useState(false);
    const location = useLocation();
    const [{ buttonText, serviceTitle }, setModalData] = useState({
        buttonText: "",
        serviceTitle: ""
    });

    useEffect(() => setVisible(false), [location]);

    useEffect(() => { }, [visible, serviceTitle, buttonText]);

    const updateModalData = (service, button) => {
        setModalData({
            buttonText: button,
            serviceTitle: service
        });
    }

    const turnOverlayOn = () => {
        setVisible(true);
    }

    const turnOverlayOff = () => {
        setVisible(false);
    }

    const turnOnFunctions = {
        modalData: updateModalData,
        modalOn: turnOverlayOn
    }

    return (
        <ModalProvider value={turnOnFunctions}>
            <MakeAppointmentModal title={serviceTitle} buttonText={buttonText} visible={visible} turnOff={turnOverlayOff} />
            { children  }
        </ModalProvider>
        );
}

export default ModalWrapper;